import * as Sentry from "@sentry/nuxt";

Sentry.init({
  dsn: "https://4c282435557c59dd88918440e73742d6@o4503998084481024.ingest.us.sentry.io/4507922140299264",

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  debug: false,
  tracePropagationTargets: ['*'],
});